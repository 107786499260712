import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ResourceCard from "../components/cards/resourceCard"
import Directory from "../components/directory/directory"

const RenderBody = ({ resource, meta, socialCard }) => (
  <>
    <Helmet
      title={"Resource Links"}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: `${meta.description}`,
        },
        {
          property: `og:title`,
          content: `${meta.title}`,
        },
        {
          property: `og:description`,
          content: `${meta.description}`,
        },
        {
          property: `og:url`,
          content: `${meta.url}/resource-links`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: `${meta.author}`,
        },
        {
          name: `twitter:title`,
          content: `${meta.title} | TIVP`,
        },
        {
          name: `twitter:description`,
          content: `${meta.description}`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
        {
          property: "og:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
      ].concat(meta)}
    />
    <div className="w-full py-24 px-8 desktop:px-0 flex justify-center items-center flex-col flex-wrap bg-gray-100">
      <div className="w-full tablet:w-3/4 desktop:w-4/5 4k:w-2/5">
        <div className="w-full flex items-baseline">
          <div className="w-full flex justify-start">
            <h1>Resource Links</h1>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 desktop:grid-cols-4 4k:grid-cols-4 gap-12">
          {resource.map((resource, i) => (
            <ResourceCard
              key={i}
              title={resource.node.title}
              date={resource.node.date}
              description={resource.node.description}
              id={resource.node.id}
              slug={resource.node.slug}
              image={resource.node.featuredImage.gatsbyImageData}
            />
          ))}
        </div>
      </div>
    </div>
  </>
)

const Articles = ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata
  const resource = data.allGraphCmsResource.edges
  const socialCard = data.file

  if (!resource) return <div>Error loading Resource Links...</div>
  if (!meta) return <div>Error loading metadata...</div>

  return (
    <Layout>
      <RenderBody resource={resource} meta={meta} socialCard={socialCard} />
      <Directory />
    </Layout>
  )
}

export default Articles

RenderBody.propTypes = {
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query AllResources {
    allGraphCmsResource(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          slug
          id
          date
          description
          featuredImage {
            gatsbyImageData
          }
        }
      }
    }
    file(name: {eq: "SocialCardTemplate-About-Us"}) {
      id
      name
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
        image
        url
        siteUrl
      }
    }
  }
`
